import { HttpClient } from "../HttpClient";
/**
 * * Patch Partner Percentage
 */
export const PatchPartnerPercentage = async (payload) => {
	try {
		const { data } = await HttpClient.patch(`api/percentages`, { ...payload });
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
