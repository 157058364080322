<template>
  <LayoutNew>
    <MDBCard id="PartnerPercentage" class="mb-2">
      <MDBCardHeader class="p-4">
        <h4 class="mb-0">Partner Percentage</h4>
        <p class="mb-0">
          <small>
            Update Partner Percentage of
            <strong class="text-capitalize">
              {{ userDetail.firstName }}
              {{ userDetail.lastName }}
            </strong>
            here.
          </small>
        </p>
      </MDBCardHeader>
      <MDBCardBody>
        <PartnerPercentageComponent />
      </MDBCardBody>
    </MDBCard>
  </LayoutNew>
</template>

<script setup>
import { MDBCard, MDBCardHeader, MDBCardBody } from "mdb-vue-ui-kit";
import LayoutNew from "./LayoutNew";
import PartnerPercentageComponent from "@/components/PartnerPercentage/PartnerPercentageComponent.vue";
import { useSendParamsDetails } from "@/store/sendParamsDetails";
import { storeToRefs } from "pinia";
import { useTitle } from "@vueuse/core";

useTitle("Partner Percentage | CreatorShield");

const sendParamsDetails = useSendParamsDetails();
const { userDetail } = storeToRefs(sendParamsDetails);
</script>

<style scoped>
#PartnerPercentage {
  color: var(--accent);
  font-family: "Poppins", sans-serif;
}

.card,
.card-body {
  overflow-y: auto;
}
</style>
