<template>
  <div class="d-grid gap-4">
    <MDBCol>
      <MDBSelect
        v-model:options="monthsOption"
        v-model:selected="partnerPercentageStore.month"
        label="Month"
        :setValue="partnerPercentageStore.month"
      />
    </MDBCol>
    <MDBCol>
      <MDBSelect
        v-model:options="yearsOption"
        v-model:selected="partnerPercentageStore.year"
        label="Year"
        :setValue="partnerPercentageStore.year"
      />
    </MDBCol>
    <MDBCol>
      <MDBInput
        label="Partner Percentage"
        v-model="partnerPercentageStore.partnerPercentage"
        type="number"
      />
    </MDBCol>
    <MDBCol class="d-flex justify-content-end">
      <MDBBtn size="sm" color="primary" @click="handleSave"
        >Save Partner Percentage</MDBBtn
      >
    </MDBCol>
  </div>
  <DeductionsToast />
</template>

<script setup>
import {
  MDBCol,
  MDBSelect,
  MDBInput,
  MDBBtn,
} from "mdb-vue-ui-kit";
import { onMounted, ref, watch } from "vue";
import { usePartnerPercentageStore } from "../../store/partnerPercentage";
import { getPreviousMonth, getCurrentYear } from "../../helpers/parseDate";
import { useRoute } from "vue-router";
import DeductionsToast from "../Deductions/DeductionsToast.vue";

const route = useRoute();
const partnerPercentageStore = usePartnerPercentageStore();
const isDropdownSet = ref(false);
onMounted(() => {
  partnerPercentageStore.month = getPreviousMonth();
  partnerPercentageStore.year = getCurrentYear();
  partnerPercentageStore.userId = route.params.id;
  setDropdown();
  partnerPercentageStore.getPartnerPercentage();
});
const setDropdown = () => {
  const newArray = monthsOption.value.map((item) => {
    if (item.value == partnerPercentageStore.month) {
      return {
        ...item,
        selected: true,
      };
    } else {
      return { ...item, selected: false };
    }
  });
  monthsOption.value = newArray;

  const newYearArray = yearsOption.value.map((item) => {
    if (item.value == partnerPercentageStore.year) {
      return {
        ...item,
        selected: true,
      };
    } else {
      return { ...item, selected: false };
    }
  });
  yearsOption.value = newYearArray;

  isDropdownSet.value = true;
};

const monthsOption = ref([
  { text: "January", value: 1 },
  { text: "February", value: 2 },
  { text: "March", value: 3 },
  { text: "April", value: 4 },
  { text: "May", value: 5 },
  { text: "June", value: 6 },
  { text: "July", value: 7 },
  { text: "August", value: 8 },
  { text: "September", value: 9 },
  { text: "October", value: 10 },
  { text: "November", value: 11 },
  { text: "December", value: 12 },
]);
const yearsOption = ref([]);
const createYearsOption = () => {
  for (let year = 2010; year <= 2100; year++) {
    yearsOption.value.push({
      text: year,
      value: year,
    });
  }
  return yearsOption.value;
};
createYearsOption();

watch(isDropdownSet, (newDropdown) => {
  if (newDropdown) {
    watch(
      () => partnerPercentageStore.month,
      (newMonth) => {
        partnerPercentageStore.month = newMonth;
        partnerPercentageStore.getPartnerPercentage();
      }
    );
    watch(
      () => partnerPercentageStore.year,
      (newYear) => {
        partnerPercentageStore.year = newYear;
        partnerPercentageStore.getPartnerPercentage();
      }
    );
  }
});

const handleSave = () => {
  const payload = {
    Id: partnerPercentageStore.id,
    PartnerPercentage: partnerPercentageStore.partnerPercentage,
  };
  if (!partnerPercentageStore.isForEdit) {
    partnerPercentageStore.postPartnerPercentage(payload);
  } else partnerPercentageStore.patchPartnerPercentage(payload);
};
</script>

<style lang="scss" scoped>
.btn-primary {
  background-color: var(--primary);
}
</style>
