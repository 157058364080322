import { defineStore } from "pinia";
import { ref } from "vue";
import { GetPartnerPercentage } from "../services/PartnerPercentage/GetPartnerPercentage";
import { PostPartnerPercentage } from "../services/PartnerPercentage/PostPartnerPercentage";
import { PatchPartnerPercentage } from "../services/PartnerPercentage/PatchPartnerPercentage";
import { useToastStore } from "../store/toastStore";
export const usePartnerPercentageStore = defineStore("partnerPercentage", () => {
	const toastStore = useToastStore();
	const month = ref("");
	const year = ref("");
	const partnerPercentage = ref();
	const userId = ref();
	const isForEdit = ref(false);
	const id = ref();

	const getPartnerPercentage = () => {
		GetPartnerPercentage(userId.value, month.value, year.value).then((response) => {
			partnerPercentage.value = response.partnerPercentage;
			isForEdit.value = response.isForEdit;
			id.value = response.id;
		});
	};
	const postPartnerPercentage = (payload) => {
		PostPartnerPercentage({
			...payload,
			UserId: userId.value,
			Month: month.value,
			Year: year.value,
		})
			.then(() => {
				toastStore.toastObject = { ...toastStore.toastObject, ...toastStore.toastStatus.success };
			})
			.catch(() => {
				toastStore.toastObject = { ...toastStore.toastObject, ...toastStore.toastStatus.fail };
			});
	};
	const patchPartnerPercentage = (payload) => {
		PatchPartnerPercentage(payload)
			.then(() => {
				toastStore.toastObject = { ...toastStore.toastObject, ...toastStore.toastStatus.success };
			})
			.catch(() => {
				toastStore.toastObject = { ...toastStore.toastObject, ...toastStore.toastStatus.fail };
			});
	};
	return {
		month,
		year,
		partnerPercentage,
		getPartnerPercentage,
		userId,
		isForEdit,
		id,
		postPartnerPercentage,
		patchPartnerPercentage,
	};
});
