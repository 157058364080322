import { HttpClient } from "../HttpClient";
/**
 * * Get Partner Percentage
 */
export const GetPartnerPercentage = async (userId, month, year) => {
	try {
		const { data } = await HttpClient.get(`api/percentages`, {
			params: {
				...(userId ? { UserId: userId } : {}),
				...(month ? { Month: month } : {}),
				...(year ? { Year: year } : {}),
			},
		});
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
