import { HttpClient } from "../HttpClient";
/**
 * * Post Partner Percentage
 */
export const PostPartnerPercentage = async (payload) => {
	try {
		const { data } = await HttpClient.post("api/percentages", { ...payload });
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
